/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  padding: 40px 0px 5px 0px;
}

.App-body {
  padding-top: 1rem;
}

/* add left and right margin so content isn't so close to the edge on larger (everything but phones) screens */
@media (min-width:641px) {
  .App-body {
    padding: 1rem;
  }
}  

.welcome {
  width: 100%;
  height: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 40em) {
  .login-button {
    width: 100%;
  }

  /* table.responsiveTable tbody td div.tdBefore {
    text-align: right !important;
  } */

  table.responsiveTable tbody tr {
    border: none;
    padding: 0;
  }

  table.responsiveTable tbody tr:not(:first-child) td.h5 {
    margin-top: .5rem;
  }

  table.responsiveTable tbody tr td.h5 {
    margin-bottom: 0;
    border-bottom: 1px solid white !important;
  }
}

/* charts */
.recharts-responsive-container { margin: auto; }

/* begin burger menu styling */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 40px;
}

@media (max-width: 480px) {
  .bm-burger-button {
    right: 20px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}
/* end burger menu styling */